
@media (max-width: 768px) {
    .modal-dialog-slideout {
        min-height: 100%;
        margin: 0 0 0 auto;
        background: #fff;
        max-width: 100% !important;
        overflow: hidden;
    }

    .modal-dialog-address {
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
        margin-left: 0 !important;
        border: none;
    }

    .modal-dialog-address-d {
        z-index: 9999;
        margin: 0rem;
    }

    .list-group-item.active {
        background-color: #3e9acd;
    }
}

.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
    background: #fff;
    max-width: 35%;
    overflow: hidden;
}

.modal-dialog-address-d {
    z-index: 9999;
}

.address-header {
    height: 18px;
}

.address-title {
    font-size: 14px;
}

.bdrop {
    z-index: 1055;
}

.bdrop-tp {
    z-index: 0;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(100%,0)scale(1);
    transform: translate(100%,0)scale(1);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    display: flex;
    align-items: stretch;
    -webkit-box-align: stretch;
    height: 100%;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
    border: 0;
}

.modal-dialog-slideout .modal-footer {
    height: auto;
    display: block;
}

.modal-dialog-slideout .modal-header h5 {
    float: left;
    color: blue;
}

.btn-check-out {
    width: 100%;
    background-color: #3e9acd;
}

.modal-bg {
    background-color: #F4F5F6;
}

.text-primary {
    color: #3e98cc !important;
}

.btn-primary,
.btn-primary .border-color,
.bg-primary {
    border-color: #3e98cc !important;
    background-color: #3e98cc !important;
    color: #fff !important;
}

.btn-primary:hover {
    border-color: #2477a6 !important;
    background-color: #2477a6 !important;
    color: #fff !important;
}

.modal-hd {
    margin: 0 0 0 auto;
    font-size: 18px;
}

.top-35 {
    margin-top: -40px
}

.top-1 {
    margin-top: -30px
}

.top-5 {
    margin-top: -5px
}

.top-2 {
    margin-top: 5px
}

.top-25 {
    margin-top: -25px
}

.p-left {
    width: 100%;
    text-align: right;
}

.email-font {
    font-size: 12px;
    font-style: italic;
    color: gray;
}

.head-font {
    font-size: 14px;
}

.head-edit-font {
    font-size: 12px;
}

.body-font {
    font-size: 12px;
}

.footer-font {
    font-size: 14px;
    font-weight: bold;
}

.font-layout {
    padding-top: 15px;
    padding-left: 15px;
}

.footer-font.footer-red {
    color: red;
}

.tb-order-list {
    padding: 0 0;
}

.tb-order {
    border-collapse: unset;
    font-size: 14px;
}

.btn-ft-size {
    font-size: 10px;
    top: 10px
}

.txt-ft-size {
    font-size: 5px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.list-width-full {
    width: 100%;
}

.mobile-check-out {
    margin-top: -20px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.color-red {
    background-color: red;
}

$primary: #3e9acd;
$secondary: #C7E1FB;
$white: #000;
$gray: #2d7dcd;

.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 50%;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $gray;
    outline: 0;
    font-size: 14px;
    color: $white;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ .form__label {
        font-size: 14px;
        cursor: text;
        top: 20px;
        font-weight: bold;
    }
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: $gray;
}

.form__button {
    position: absolute;
    top: 30px;
    display: block;
    right: 0;
}

.form__field:focus {
    ~ .form__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 14px;
        color: $primary;
        font-weight: 400;
    }

    padding-bottom: 6px;
    font-weight: 400;
    border-width: 3px;
    border-image: linear-gradient(to right, $primary,$secondary);
    border-image-slice: 1;
}
/* reset input */
.form__field {
    &:required, &:invalid {
        box-shadow: none;
    }
}



.btn-container {
    width: 15%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg);

    &:nth-child(1) {
        --btn-bg: #3e9acd;
        --bg: #C7E1FB;
    }
}

button {
    --width: 50px;
    --height: 20px;
    border: 0;
    position: relative;
    min-width: var(--width);
    min-height: var(--height);
    border-radius: var(--height);
    color: #3e9acd;
    font-weight: bold;
    background: #C7E1FB;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    font-family: "open_sansregular","promptregular",arial,helvetica,sans-serif !important;

    .text,
    .icon-container {
        position: relative;
        z-index: 2;
    }

    .icon-container {
        position: relative;
        width: var(--icon-size);
        height: var(--icon-size);
        margin-left: 5px;
        transition: transform 300ms ease;

        .icon {
            position: absolute;
            left: 0;
            top: 0;
            width: var(--icon-size);
            height: var(--icon-size);
            transition: transform 300ms ease, opacity 200ms ease;

            &--left {
                transform: translateX(-100%);
                opacity: 0;
            }

            svg {
                width: 100%;
                height: 100%;
                fill: #2196F3;
            }
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--btn-bg);
        border-radius: var(--height);
        z-index: 1;
        transition: transform 300ms ease;
    }

    &:hover {
        &::after {
            transform: translateX(65%);
        }

        .icon-container {
            transform: translateX(125%);

            .icon {
                &--left {
                    transform: translateX(0);
                    opacity: 1;
                }

                &--right {
                    transform: translateX(200%);
                    opacity: 0;
                }
            }
        }
    }
}

.support {
    position: absolute;
    right: 8px;
    bottom: 8px;
    padding: 8px;
    display: flex;

    a {
        margin: 0 8px;
        color: #fff;
        font-size: 14px;
        backface-visibility: hidden;
        transition: all 150ms ease;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.ic-info-circle {
    width: 20px;
}

.payment-method {
    input {
        padding: 0;
    }
}
