/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* ProductDetail */
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-slide-cover {
  background-image: url(assets/images/products_detail/inner_shadow.png);
  background-size: 100%;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  pointer-events: none;
}

.ribbon.discount{
  width: 60px;
  height: 60px;
  z-index: 8;
  text-align: center;
  background-image: url(assets/images/ribbon_discount.png);
  padding-top: 7px;
}

.swiper-next .carousel-control-next-icon {
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(assets/images/arrow_right.png);
  opacity: 1;
}
.swiper-prev .carousel-control-prev-icon {
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(assets/images/arrow_left.png);
  opacity: 1;
}
.container .content.product_detail{
  padding-left: 0px;
  padding-right: 0px;
}
.container .content.product_detail .description {
  width: 100%;
}

.product_detail .delivery .tooltip {
  opacity: 1;
}

.tab_content .tab.detail.selected ,
.content.product_detail .tab_content .tab.detail.selected i,
.tab_content .tab.detail.selected i{
   background-image: none;
}

.content.product_detail .tab_content .tab i,
.content.product_detail .tab_content .tab b{
  cursor: pointer;
}
.content.product_detail .tab_content .tab {

  cursor: initial;
}
.content.product_detail .tab_content .tab .tab_header {
  padding: 10px 0px;
}
.content.product_detail .tab_content .tab .tab_body {
  display: none;
  padding: 0px 0px;
}
.content.product_detail .tab_content .tab.selected .tab_body {
  display: block;
}

.text-completed{
  color: #6ecbb8
}
.text-failed{
  color:#b93838
}

.margin-100 {
  margin: 100px 0 100px 0;
}