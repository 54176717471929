/*!
* Iconic Pharmacy
* https://www.iconicpharmacy.co.th
* @license Copyright 2020, Iconic Pharmacy. All rights reserved.
* @author: atthaphon@dnaagency.co.th
*/

html, body {
  position: relative;
  height: auto;
 }
 body {
  /*font-family: "Myriad Pro", Myriad, "Helvetica Neue", Helvetica, Arial, sans-serif;*/
  /*font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;*/
  /*font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;*/
  /*font-family:'open_sansregular', arial, helvetica, sans-serif;*/
  line-height: 1.2;
  letter-spacing: -.01em;
  font-size: 14px;
  color:#333;
  margin: 0;
  padding: 0;
  background-color: #fff;
 }
 a{
   color:#444;
   transition-duration: 0.1s;
   outline: none;
   text-decoration: none;
 }
 a:hover, a i:hover{
   color:#222;
   text-decoration: underline;
   outline: none;
 }
 *, ::after, ::before {
     box-sizing: border-box;
 }
 div{
   outline: none;
 }
 select {
     -moz-appearance:none;
     -webkit-appearance:none;
     appearance:none;
     background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
     background-repeat: no-repeat;
     background-position: right;
 }
 ::-webkit-input-placeholder { /* Edge */
   color: #ccc;
 }
 :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #999;
 }
 ::placeholder {
   color: #999;
 }
 .pos { position:absolute; }
 .no_point, .no_point:hover{
   cursor: default!important;
   text-decoration: none!important;}
 .show_mobile{
   display: none;
 }
 .show_pc{
   display: block;
 }
 .br_480{
   display: none;
 }
 html.fix, body.fix{
   overflow: hidden;
   /*margin-top: 0px;*/
 }
 /* Fix Bootstrap */

 .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
     padding-right: 10px;
     padding-left: 10px;
 }

.rows {
    margin-right: -15px;
    margin-left: -15px;
    width: 105%;
}

@media (min-width: 992px) {
    .cols-lg-1, .cols-lg-2, .cols-lg-3, .cols-lg-4, .cols-lg-5, .cols-lg-6, .cols-lg-7, .cols-lg-8, .cols-lg-9, .cols-lg-10, .cols-lg-11, .cols-lg-12 {
        float: left;
    }
}

@media (min-width: 768px) {
    .cols-md-1, .cols-md-2, .cols-md-3, .cols-md-4, .cols-md-5, .cols-md-6, .cols-md-7, .cols-md-8, .cols-md-9, .cols-md-10, .cols-md-11, .cols-md-12 {
        float: left;
    }
}
/*@media (max-width: 1080px) {
    .thumb.cols-lg-3.cols-md-4.cols-xs-6 {
        width: 33.333%;
    }
}*/
@media (min-width: 768px) {
    .cols-md-4 {
        width: 33.33333333%;
        /*width: 25%;*/
    }
}

@media (min-width: 992px) {
    .cols-lg-3 {
        width: 25%;
    }
}

@media (max-width: 820px) {
    .thumb.cols-lg-3.cols-md-4.cols-xs-6 {
        width: 50%;
    }
}
/*.cols-xs-6 {
    width: 50%;
}*/
.cols-xs-1, .cols-xs-2, .cols-xs-3, .cols-xs-4, .cols-xs-5, .cols-xs-6, .cols-xs-7, .cols-xs-8, .cols-xs-9, .cols-xs-10, .cols-xs-11, .cols-xs-12 {
    float: left;
}

.cols-xs-1, .cols-sm-1, .cols-md-1, .cols-lg-1, .cols-xs-2, .cols-sm-2, .cols-md-2, .cols-lg-2, .cols-xs-3, .cols-sm-3, .cols-md-3, .cols-lg-3, .cols-xs-4, .cols-sm-4, .cols-md-4, .cols-lg-4, .cols-xs-5, .cols-sm-5, .cols-md-5, .cols-lg-5, .cols-xs-6, .cols-sm-6, .cols-md-6, .cols-lg-6, .cols-xs-7, .cols-sm-7, .cols-md-7, .cols-lg-7, .cols-xs-8, .cols-sm-8, .cols-md-8, .cols-lg-8, .cols-xs-9, .cols-sm-9, .cols-md-9, .cols-lg-9, .cols-xs-10, .cols-sm-10, .cols-md-10, .cols-lg-10, .cols-xs-11, .cols-sm-11, .cols-md-11, .cols-lg-11, .cols-xs-12, .cols-sm-12, .cols-md-12, .cols-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.cols, .cols-1, .cols-10, .cols-11, .cols-12, .cols-2, .cols-3, .cols-4, .cols-5, .cols-6, .cols-7, .cols-8, .cols-9, .cols-auto, .cols-lg, .cols-lg-1, .cols-lg-10, .cols-lg-11, .cols-lg-12, .cols-lg-2, .cols-lg-3, .cols-lg-4, .cols-lg-5, .cols-lg-6, .cols-lg-7, .cols-lg-8, .cols-lg-9, .cols-lg-auto, .cols-md, .cols-md-1, .cols-md-10, .cols-md-11, .cols-md-12, .cols-md-2, .cols-md-3, .cols-md-4, .cols-md-5, .cols-md-6, .cols-md-7, .cols-md-8, .cols-md-9, .cols-md-auto, .cols-sm, .cols-sm-1, .cols-sm-10, .cols-sm-11, .cols-sm-12, .cols-sm-2, .cols-sm-3, .cols-sm-4, .cols-sm-5, .cols-sm-6, .cols-sm-7, .cols-sm-8, .cols-sm-9, .cols-sm-auto, .cols-xl, .cols-xl-1, .cols-xl-10, .cols-xl-11, .cols-xl-12, .cols-xl-2, .cols-xl-3, .cols-xl-4, .cols-xl-5, .cols-xl-6, .cols-xl-7, .cols-xl-8, .cols-xl-9, .cols-xl-auto {
    padding-right: 10px;
    padding-left: 10px;
}
 
 /* Fix Swiper */
 
 
 .swiper-style .swiper-button-next:after, .swiper-style .swiper-button-prev:after {
   font-size: 0;
 }
 .swiper-style .swiper-button-next, .swiper-style .swiper-container-rtl .swiper-button-prev {
   width: 50px;
   height: 50px;
   background-size: 100%;
   background-repeat: no-repeat;
   background-image: url(./../images/arrow_right.png);
   top: 50%;
 }
 .swiper-style .swiper-button-prev, .swiper-style .swiper-container-rtl .swiper-button-next {
   width: 50px;
   height: 50px;
   background-size: 100%;
   background-repeat: no-repeat;
   background-image: url(./../images/arrow_left.png);
   top: 50%;
 }
 .swiper-style .swiper-pagination-bullet {
   width: 14px;
   height: 14px;
 }
 .swiper-style .swiper-pagination-bullet.swiper-pagination-bullet-active{
   background-size: 100%;
   background-repeat: no-repeat;
   background-image: url(./../images/swiper_bullet.png);
   background-color: #fff;
 }
 
 .pos{
   position: absolute;
   z-index: auto;
 }
 .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
   bottom: 20px;
 }
 .s_mobile_content { position:relative; margin:0 auto; width:100%; max-width:750px; line-height: 1; margin-top: 10%;}
 .s_pc {
   background-position: center;
   background-size: 100% auto;
 }
 .s_pc_content { position:relative; margin:0 auto; width:100%; max-width:1366px; line-height: 1; margin-top: 2%;}
 img { width: 100%;vertical-align: middle;border-style: none;}
 .w_bt{
   background-color: #94c608;
   border-radius: 99px;
   padding: 1%;
   font-size:min(2.2vw , 28px);
   color:#FFF;
   text-align:center;
   cursor:pointer;
   transition-duration: 0.1s;
 }
 .w_bt:hover{
   background-color: #FFF;
   color: #000;
   margin-top: -10px;
 }
 
 .s_bt{
   background-color: #94c608;
   border-radius: 99px;
   padding: 1%;
   font-size:min(2.2vw , 28px);
   color:#FFF;
   text-align:center;
   cursor:pointer;
   transition-duration: 0.1s;
 }
 .s_bt:hover{
   background-color: #000;
   margin-top: -10px;
 }
 
  input, select, textarea{
    border: 1px solid #ddd;
    padding: 10px 15px;
    color: #555;
    height: 40px;
    vertical-align: top;
  }
  textarea{
   width: 100%;
   height: auto;
  }
 
 
 
 .bgimg{
   display: inline-block;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
 }
 .crumbs{
   font-size: min(4vw,14px);
   width: 100%;
   max-width: 1280px;
   margin: auto;
   padding: 20px 15px;
   line-height: 1.6;
 }
 .crumbs a{
   display: inline;
   padding-right: 20px;
   background-repeat: no-repeat;
   background-size: 11px;
   background-position: right 60%;
   background-image: url(./../images/ic_right_g.png);
   margin-right: 5px;
 }
 .crumbs a:last-child{
   background-image: none;
   /*color: #999;*/
 }
 .crumbs a:last-child:hover{
   text-decoration: none;
 }
 
 .break_mobile{
   border-top: 0px;
 }
 /* Hide Input Password Eye (IE) */
 input::-ms-reveal,input::-ms-clear {
   display: none;
 }
 .input_password{
   position: relative;
 }
 .input_password b{
   display: block;
   position: absolute;
   cursor: pointer;
   top: 10px;
   right: 15px;
   width: 22px;
   height: 30px;
   background-size: contain;
   background-repeat: no-repeat;
   background-image: url(./../images/sign_in/eye_off.png);
 }
 .input_password input[type=text] ~ b{
   background-image: url(./../images/sign_in/eye.png);
 }
 #iconic_topbar .popup #popup_container.email {
   max-width: 600px;
   background-color: #fff;
   padding: 40px;
   text-align: center;
   width: 90%;
 }
 #popup_container.email h2 {
     padding-bottom: 0px;
 }
 #popup_container.email .close {
     top: -20px!important;
     right: -20px!important;
 }
 #popup_container.email span {
     display: block;
     margin: 20px 0 10px;
     text-align: left;
     width: 100%;
 }
 #popup_container.email input {
     width: 48.5%;
     margin-bottom: 15px;
 }
 #popup_container.email input.left {
     margin-right: 10px;
 }
 #popup_container.email .bt {
     display: block;
     padding: 15px;
     color: #fff;
     text-align: center;
     cursor: pointer;
     background-color: #3d97d4;
     width: 200px;
     right: 0;
     float: right;
     margin-right: 0;
 }
 .input_email{
   margin-bottom: 10px;
 }
 
 /* Interface */
 #iconic_topbar{
   width: 100%;
   height: 110px;
   background-color: #fff;
   border-bottom: 1px solid #dcdcdc;
   position: relative;
   top: 0;
   left: 0;
   z-index: 20;
 }
 #iconic_topbar .menu_bg{
   background-color: #fff;
   width: 100%;
   height: 89px;
   position: absolute;
 }
 #iconic_topbar .menu_logo{
   width: 40%;
   max-width: 160px;
   height: 65%;
   left: 20px;
   background-image: url(./../images/iconicpharmacy.png);
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   position: absolute;
 }
 #iconic_topbar .menu_search_box{
   position: absolute;
   width: 44%;
   height: 37px;
   top: 17px;
   border: 1px solid #dcdcdc;
   background-color: #f9f9f9;
   left: 50%;
   margin-left: -21.5%;
 }
 #iconic_topbar .menu_search_box input{
   border: 0px;
   background: transparent;
   width: 80%;
   padding: 5px 15px;
   height: 35px;
 }
 #iconic_topbar .menu_search_box b{
   border-left: 1px solid #dcdcdc;
   width: 20%;
   max-width: 80px;
   height: 26px;
   margin-top: 5px;
   display: block;
   float: right;
   cursor: pointer;
   background-image: url(./../images/search.png);
   background-size: 24px;
   background-repeat: no-repeat;
   background-position: center;
 }
 #iconic_topbar .menu_search_box b:hover{
   background-image: url(./../images/search_over.png);
 }
 #iconic_topbar .menu_super{
   float: right;
   text-align: right;
   position: absolute;
   right: 20px;
   top: 25px;
 }
 #iconic_topbar .menu_super .menu_account_super{
   background-image: url(./../images/ic_profile.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 60%;
   cursor: pointer;
   width: 40px;
   height: 40px;
   margin-top: -7px;
   display: inline-block;
   opacity: 0.7;
   float: right;
 }
 #iconic_topbar .menu_super .menu_account_super.active{
   background-image: url(./../images/account/ic_profile.png);
   border: 1px solid #ddd;
   border-radius: 99px;
   width: 34px;
   height: 34px;
   margin-top: -5px;
   margin-right: 4px;
   background-size: cover;
 }
 #iconic_topbar .menu_super .menu_account_super:hover{
   opacity: 1;
 }
 #iconic_topbar .menu_account_sub{
   width: 100%;
   transition-duration: 0.3s;
   right: -250px;
   top: 50px;
   z-index: 20;
   background-color: #fff;
   height: 100%;
   position: fixed;
   max-width: 250px;
   border-top: 1px solid #ddd;
 }
 #iconic_topbar .menu_account_sub h2{
   padding-left: 20px;
   font-size: min(4vw,18px);
   margin-bottom: 10px;
   padding-top: 30px;
 }
 #iconic_topbar .menu_account_sub.selected {
   right: 0px;
 }
 #iconic_topbar .menu_account_sub span{
   display: block;
   border-bottom: 1px solid #ddd;
   text-transform: uppercase;
 }
 #iconic_topbar .menu_account_sub span:last-child {
     border-bottom: 0px;
 }
 #iconic_topbar .menu_account_sub span a{
   display: inline-block;
   padding: 10px 20px 10px 20px;
 }
 #iconic_topbar .menu_account_sub .menu_ic_close{
   width: 40px;
   height: 40px;
   right: 0;
   top: 0;
   background-repeat: no-repeat;
   background-size: 80%;
   background-position: center;
   background-image: url(./../images/ic_x_b.png);
 }
 #iconic_topbar .menu_account_sub .menu_ic_close:hover{
   background-image: url(./../images/ic_x_c.png);
 }
 
 
 #iconic_topbar .menu_super .menu_cart_super {
     background-image: url(./../images/ic_cart.png);
     background-position: center;
     background-repeat: no-repeat;
     background-size: 60%;
     cursor: pointer;
     width: 40px;
     height: 40px;
     margin-top: -7px;
     display: inline-block;
     opacity: 0.7;
     float: right;
 }
 #iconic_topbar .menu_super .menu_cart_super:hover {
     opacity: 1;
 }
 
 #iconic_topbar .menu_super .menu_language{
   cursor: pointer;
   padding-left: 15px;
   display: none;
   opacity: 0.7;
   float: right;
   height: 30px;
   padding-top: 7px;
   font-size: 16px;
   letter-spacing: 1.2px;
 }
 #iconic_topbar .menu_super .menu_language.show{
   display: inline-block;
 }
 #iconic_topbar .menu_super .menu_language span{
   display: inline-block;
   width: 10px;
   height: 10px;
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   background-image: url(./../images/ic_dropdown_g.png);
   margin-left: 5px;
 }
 
 #iconic_topbar .menu_super .menu_language_expand{
   position: absolute;
   z-index: 21;
   width: 140px;
   padding: 10px;
   border-radius: 4px;
   background-color: #fff;
   box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
   margin-top: 30px;
   display: none;
   right: -5px;
   border: 1px solid #ccc;
 }
 #iconic_topbar .menu_super .menu_language_expand span{
   display: block;
   text-align: left;
   padding: 10px;
   font-size: 14px;
   cursor: pointer;
   border-radius: 5px;
 }
 #iconic_topbar .menu_super .menu_language_expand span:hover{
   background-color: #eef0f1;
 }
 #iconic_topbar .menu_super .menu_language_expand span i{
   width: 30px;
   height: 20px;
   margin: 0 5px;
   vertical-align: middle;
   display: inline-block;
 }
 #iconic_topbar .menu_super .menu_language_expand span .en{
   background: url(./../images/flag.png);
   background-size: 100%;
   background-position-y: 0px;
 }
 #iconic_topbar .menu_super .menu_language_expand span .th{
   background: url(./../images/flag.png);
   background-size: 100%;
   background-position-y: -22px;
 }
 #iconic_topbar .menu_list {
   width: 100%;
   bottom: -8px;
   position: absolute;
   text-align: center;
   font-size: min(2.5vw, 14px);
   padding-bottom: 20px;
   padding-top: 5px;
 }
 #iconic_topbar .menu_list .menu_main{
   padding-left: 25px;
   display: inline-block;
   text-decoration: none;
   background-position: top left;
   background-repeat: no-repeat;
   background-size: auto 80%;
   margin-left: 20px;
   padding: 2px 0px 3px 25px;
   cursor: pointer;
 }
 #iconic_topbar .menu_list .menu_main.m1{
   /*margin-left: 100px;*/
 }
 #iconic_topbar .menu_list .menu_main:hover{
   text-decoration: underline;
 }
 .menu_list .menu_main.selected_fix  {
   color: #337ab7;
 }
 .menu_list .menu_main.m1{
   background-image: url(./../images/menu_health.png);
 }
 .menu_list .menu_main.m1.selected, .menu_list .menu_main.m1:hover, .menu_list .menu_main.m1.selected_fix{
   background-image: url(./../images/menu_health_over.png);
 }
 .menu_list .menu_main.m2{
   background-image: url(./../images/menu_product.png);
 }
 .menu_list .menu_main.m2.selected, .menu_list .menu_main.m2:hover, .menu_list .menu_main.m2.selected_fix{
   background-image: url(./../images/menu_product_over.png);
 }
 .menu_list .menu_main.m3{
   background-image: url(./../images/menu_pharmacy.png);
 }
 .menu_list .menu_main.m3.selected, .menu_list .menu_main.m3:hover, .menu_list .menu_main.m3.selected_fix{
   background-image: url(./../images/menu_pharmacy_over.png);
 }
 .menu_list .menu_main.m4{
   background-image: url(./../images/menu_member.png);
 }
 .menu_list .menu_main.m4.selected, .menu_list .menu_main.m4:hover, .menu_list .menu_main.m4.selected_fix{
   background-image: url(./../images/menu_member_over.png);
 }
 .menu_list .menu_main.m5{
   background-image: url(./../images/menu_contact.png);
 }
 .menu_list .menu_main.m5.selected, .menu_list .menu_main.m5:hover, .menu_list .menu_main.m5.selected_fix{
   background-image: url(./../images/menu_contact_over.png);
 }
 #iconic_topbar .menu_list .menu_sub{
   width: 100%;
   position: absolute;
   z-index: 21;
   left: 0;
   top: 38px;
   background-color: #fff;
   display: block;
   display: none;
   padding-left: 15%;
   text-align: left;
   padding: 15px 0px 30px 15%;
   font-size: min(1.7vw, 14px);
   border-top: 1px solid #ddd;
   box-shadow: 0px 10px 15px rgb(0 0 0 / 3%);
 }
 #iconic_topbar .menu_list .menu_sub a{
   display: inline-block;
   text-decoration: none;
   width: 22%;
   padding: 10px;
   vertical-align: top;
 }
 #iconic_topbar .menu_list .menu_sub a:hover{
   text-decoration: none;
   color: #3d99cb;
 }
 
 #iconic_topbar .popup{
   width: 100%;
   height: 100%;
   margin: 0 auto;
   position: fixed;
   z-index: 40;
   text-align: center;
   vertical-align: middle;
   display: none;
 }
 #iconic_topbar .popup_fader{
   width: 100%;
   height: 100%;
   margin: 0 auto;
   position: fixed;
   z-index: auto;
   background-color: rgba(0, 0, 0, 0.7);
 }
 #iconic_topbar .popup.show{
   display: flex!important;
 }
 
 #iconic_topbar .popup .close{
   width: 50px;
   height: 50px;
   position: absolute;
   top: 0;
   right: 0;
   background-color: #4097cc;
   cursor: pointer;
   border-radius: 99px;
   background-image: url(./../images/ic_x.png);
   background-position: center;
   background-size: 70%;
 }
 #iconic_topbar .popup .close:hover{
   background-color: #2274a5;
 }
 #iconic_topbar .popup #popup_container{
   margin: auto;
   position: relative;
   padding: 20px;
   width: 100%;
 }
 #iconic_topbar .popup #popup_container.vdo{
   max-inline-size: 894px;
 }
 #iconic_topbar .popup #popup_container iframe{
   width: 100%;
 }
 
 .menu_ic_close {
   display: inline-block;
   cursor: pointer;
   padding: 10px 15px;
   display: none;
   /*background-color: #fff;
   border-bottom: 1px solid #ddd;*/
 }
 .bar1, .bar2, .bar3 {
   width: 25px;
   height: 1px;
   background-color: #343434;
   margin: 6px 0;
   transition: 0.3s;
 }
 .show .bar1 {
   -webkit-transform: rotate(-45deg) translate(-5px, 5px);
   transform: rotate(-45deg) translate(-5px, 5px);
 }
 .show .bar2 {opacity: 0;}
 .show .bar3 {
   -webkit-transform: rotate(45deg) translate(-5px, -5px);
   transform: rotate(45deg) translate(-5px, -5px);
 }
 .menu_fader{
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0);
   position: fixed;
   display: none;
 }
 .gototop{
   position: fixed;
   width: 60px;
   padding: 5px;
   background-color: #fff;
   border-radius: 99px;
   right: 20px;
   bottom: 40px;
 }
 .gototop .goup{
   width: 50px;
   height: 50px;
   background-color: #fff;
   border: 1px solid #666;
   border-radius: 99px;
   cursor: pointer;
   padding: 14px;
   margin-bottom: 5px;
   display: none;
 }
 
 .gototop .chat{
   width: 50px;
   height: 50px;
   background-color: #0099d2;
   border-radius: 99px;
   cursor: pointer;
   padding: 10px;
 }
 .gototop .chat img {
   filter: brightness(1000%);
 }
 .gototop .goup:hover {
   background-color: #0099d2;
   border: 1px solid #0099d2;
 }
 .gototop .goup:hover img, .gototop .chat:hover img {
   filter: brightness(1000%);
 }
 
 #iconic_topbar .popup #popup_container.sign_in{
   max-width: 500px;
   background-color: #fff;
   padding: 40px;
   text-align: left;
   width: 90%;
 }
 #popup_container.sign_in .close{
   top:-20px;
   right: -20px;
 }
 #popup_container.sign_in a{
   color: #0099d2;
 }
 #popup_container.sign_in h2{
   margin: 0;
   font-size: min(8vw,32px);
   margin-bottom: 20px;
 }
 #popup_container.sign_in .create{
   display: block;
   margin-bottom: 20px;
 }
 #popup_container.sign_in input{
   display: block;
   width: 100%;
   margin: 5px 0 15px;
 }
 #popup_container.sign_in .password_open{
   display: block;
   width: 40px;
   height: 40px;
   position: absolute;
   right: 45px;
   margin-top: 5px;
   background-image: url(./../images/sign_in/eye.png);
   background-size: 70%;
   background-repeat: no-repeat;
   background-position: center;
   cursor: pointer;
   opacity: 0.5;
 }
 #popup_container.sign_in .password_open:hover{
   opacity: 0.9;
 }
 #popup_container.sign_in .bt{
   display: block;
   padding: 15px;
   color: #fff;
   text-align: center;
   cursor: pointer;
   opacity: 0.8;
   max-width: 100%;
   width: auto;
 }
 #popup_container.sign_in .bt:hover{
   opacity: 1;
 }
 #popup_container.sign_in .bt span{
   width: 20px;
   height: 20px;
   display: inline-block;
   margin-right: 10px;
   margin-bottom: -5px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: 20px;
 }
 #popup_container.sign_in .bt.sn{
   background-color: #247bb6;
   width: 150px;
   right: 0;
   float: right;
   margin-bottom: 30px;
 }
 
 #popup_container.sign_in .bt.fb{
   background-color: #233d78;
   margin-bottom: 10px;
 }
 #popup_container.sign_in .bt.fb span{
   background-image: url(./../images/sign_in/facebook.png);
 }
 #popup_container.sign_in .bt.line{
   background-color: #689500;
   margin-bottom: 10px;
 }
 #popup_container.sign_in .bt.line span{
   background-image: url(./../images/sign_in/ine.png);
 }
 #popup_container.sign_in .bt.gg{
   border: 1px solid #ddd;
   margin-bottom: 10px;
   color: #111;
 }
 #popup_container.sign_in .bt.gg span{
   background-image: url(./../images/sign_in/google.png);
 }
 #popup_container.sign_in .hr{
   width: 100%;
   margin: 20px 0;
   text-align: center;
   clear: both;
   border-bottom: 1px solid #ddd;
 }
 #popup_container.sign_in .hr span{
   padding: 10px;
   background-color: #fff;
   text-align: center;
   margin-top: -20px;
   position: absolute;
   margin-left: -10px;
 }
 
 
 #iconic_topbar .popup #popup_container.sign_out{
   max-width: 400px;
   background-color: #fff;
   padding: 40px;
   text-align: center;
   width: 90%;
 }
 #popup_container.sign_out .close{
   top:-20px;
   right: -20px;
 }
 #popup_container.sign_out .icon{
   background-repeat: no-repeat;
     max-width: 80%;
     width: 120px;
     height: 120px;
     background-image: url(./../images/popup/right.png);
     border-radius: 999px;
     background-color: #489ed1;
     display: block;
     background-position: center;
     margin: 0 auto;
     background-size: 50%;
 }
 #popup_container.sign_out .text_blue{
   color:#4097cc;
   font-size: min(6vw,22px);
   display: block;
 }
 
 #iconic_topbar .popup #popup_container.alert{
   max-width: 400px;
   background-color: #fff;
   text-align: center;
   width: 90%;
   padding: 0;
   text-align: center;
 }
 #popup_container.alert .close{
   top:-20px;
   right: -20px;
 }
 #popup_container.alert .alert_text{
   padding: 50px 40px;
   font-size: 18px;
   line-height: 1.4;
 }
 #popup_container.alert .bt{
   display: inline-block;
   width: 49.9%;
   padding: 15px;
   border-top: 1px solid #ddd;
   border-left: 1px solid #ddd;
   cursor: pointer;
   font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
 }
 #popup_container.alert .bt:hover{
   color: #fff;
   border-left: 1px solid #3e98cc;
   background-color: #3e98cc;
 }
 #popup_container.alert .bt.selected{
   color: #fff;
   background-color: #2683b8;
 }
 #popup_container.alert .bt.selected:hover{
   border-left: 1px solid #2683b8;
 }
 #popup_container.alert .bt:first-child{
   border-left: 0px;
 }
 #popup_container.alert .bt.full{
   width: 100%;
   border-left: 0px;
 }
 
 #iconic_topbar .popup #popup_container.password{
   max-width: 400px;
   background-color: #fff;
   padding: 40px;
   text-align: center;
   width: 90%;
 }
 #popup_container.password .close{
   top:-20px;
   right: -20px;
 }
 #popup_container.password span{
   display: block;
   margin: 20px 0 10px;
   text-align: left;
   width: 100%;
 }
 #popup_container.password input{
   width: 100%;
   margin-bottom: 15px;
 }
 #popup_container.password .bt{
   display: block;
   padding: 15px;
   color: #fff;
   text-align: center;
   cursor: pointer;
   background-color: #3d97d4;
   width: 200px;
   right: 0;
   float: right;
 }
 #popup_container.password .bt:hover{
   background-color: #1d74ae;
 }
 
 
 
 
 
 #iconic_footer{
   background-color: #f9f9f9;
   display: block;
   width: 100%;
   margin-top: 20px;
 }
 #iconic_footer .iconic_footer_content{
   width: 100%;
   max-width: 1280px;
   margin: 0 auto;
   clear: both;
 }
 #iconic_footer .enews{
   display: inline-block;
   width: 39%;
   vertical-align: top;
   padding: 20px 15px;
   padding-right: 60px;
 }
 #iconic_footer .enews b{
   display: block;
   font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
   font-weight: normal;
   margin-bottom: 10px;
   font-size: min(5vw,14px);
 }
 #iconic_footer .enews input{
   display: inline-block;
   width: 70%;
   height: 40px;
   float: left;
 }
 #iconic_footer .enews span{
   display: inline-block;
   width: 29%;
   text-align: center;
   background-color: #333;
   color: #fff;
   padding: 10px;
   height: 40px;
   float: left;
   cursor: pointer;
 }
 #iconic_footer .enews span:hover {
   background-color: #0099d2;
 }
 #iconic_footer .social{
   display: inline-block;
   width: 60%;
   padding-left: 60px;
   padding: 30px;
   border-left: solid 1px #ddd;
   font-size: min(3.5vw,12px);
 }
 #iconic_footer .social b{
   display: block;
   font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
   font-weight: normal;
   margin-bottom: 10px;
   font-size: min(5vw,14px);
 }
 #iconic_footer .social img{
   display: inline-block;
   width: 18%;
   margin-right: 10px;
   min-width: 20px;
 }
 #iconic_footer .social i{
   display: inline-block;
   vertical-align: middle;
   font-style: normal;
 }
 #iconic_footer .social span{
   display: block;
   font-weight: normal;
   margin-bottom: 20px;
 }
 #iconic_footer .social div{
   display: inline-block;
   width: 24.5%;
   font-weight: normal;
   margin-bottom: 15px;
 }
 #iconic_footer .link{
   display: block;
   border-top: solid 1px #ddd;
   text-align: center;
   width: 100%;
   font-weight: normal;
   padding-top: 30px;
   margin-bottom: 30px;
   font-size: min(3vw,12px);
   clear: both;
 }
 #iconic_footer .link a{
   display: inline;
   padding: 0 20px;
 }
 #iconic_footer .copy{
   text-align: center;
   width: 100%;
 }
 #iconic_footer .copy img{
   width: 50%;
   max-width: 140px;
 }
 #iconic_footer .copy b{
   font-weight: normal;
   display: block;
   text-align: center;
   padding: 20px;
   font-size: min(3.5vw,12px);
 }
 
 #iconic_footer .tab_content{
   width: 100%;
   border-top: solid 1px #ddd;
   border-bottom: solid 1px #ddd;
   display: block;
   padding: 0px 15px;
   height: 55px;
 }
 #iconic_footer .tab_content_fix{
   max-width: 1250px;
   position: relative;
   height: 100%;
   margin: 0 auto;
 }
 #iconic_footer .tab_content .tab{
   width: 33%;
   max-width: 140px;
   padding: 15px 5px;
   display: inline-block;
   font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
   text-align: center;
   height: 100%;
   float: left;
   vertical-align: middle;
   line-height: 2;
   border-right: 1px solid #d0d0d0;
   cursor: pointer;
 }
 
 #iconic_footer .tab_content .tab:first-child{
   border-left: 1px solid #d0d0d0;
 }
 
 #iconic_footer .tab_content .tab img{
   height: 100%;
   width: auto;
   margin-top: -5px;
 }
 #iconic_footer .tab_content .tab.selected , #iconic_footer .tab_content .tab:hover{
   background-color: #e6e6e6;
 }
 
 #iconic_footer .tab_content .share{
   float: right;
   display: inline-block;
   text-align: right;
   vertical-align: middle;
   height: 55px;
   position: relative;
 }
 #iconic_footer .tab_content .share b{
   vertical-align: middle;
   display: inline-block;
   float: left;
   margin-top: 10%;
 }
 #iconic_footer .tab_content .share a{
   display: inline-block;
   height: 55px;
   width: 50px;
   max-width: 50px;
   text-align: center;
   background-position: center;
   background-repeat: no-repeat;
   background-size: 55%;
 }
 #iconic_footer .tab_content .share a.fb{
   background-image: url(./../images/ic_fb.png);
 }
 #iconic_footer .tab_content .share a.fb:hover{
   background-image: url(./../images/ic_fb_over.png);
 }
 #iconic_footer .tab_content .share a.line{
   background-image: url(./../images/ic_line.png);
 }
 #iconic_footer .tab_content .share a.line:hover{
   background-image: url(./../images/ic_line_over.png);
 }
 #iconic_footer .tab_content .share img{
   width: 100%;
   max-width: 30px;
   display: inline-block;
 }
 #iconic_footer .default{
   display: block;
 }
 #iconic_footer .sitemap{
   display: none;
   padding: 20px;
 }
 #iconic_footer .column{
   display: inline-block;
   width: 18%;
   float: left;
   font-size: min(3vw,12px);
   padding: 0px 0px 20px 20px;
 }
 #iconic_footer .column.md{
   width: 20%;
 }
 #iconic_footer .column.lg{
   width: 22%;
 }
 #iconic_footer .menu_footer{
 
 }
 #iconic_footer .column .menu_sub a{
   color: #666;
 }
 #iconic_footer .column a{
   display: block;
   width: 100%;
   padding: 3px 0;
   color: #777;
 }
 #iconic_footer .column a.menu_footer{
   color: #333;
 }
 
 
 /* Animation */
 .page_load {
   background-image: url(./../images/preload.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   width:40px;
   height: 40px;
   margin: 20px auto;
   -webkit-animation: spin 1s infinite linear;
 }
 
 /* Infinit load*/
 .page-load-status {
   display: none; /* hidden by default */
   padding-top: 20px;
   border-top: 1px solid #DDD;
   text-align: center;
   color: #777;
 }
 
 .table_holder{
   width: 100%;
   overflow: auto;
 }
 
 @-webkit-keyframes spin {
     from {-webkit-transform: rotate(0deg);}
     to   {-webkit-transform: rotate(359deg);}
 }
 
 @media only screen and (max-width: 1152px) {
   /*#iconic_topbar .menu_bg{
     height: 119px;
   }
   #iconic_topbar{
     height: 120px;
   }*/
   #iconic_topbar .menu_list .menu_sub{
     padding: 15px 0px 30px 4%;
   }
   #iconic_topbar .menu_list .menu_sub a{
     width: 32.5%
   }
   #iconic_topbar .menu_logo{
     width: 40%;
     max-width: 160px;
     height: 65%;
     left: 20px;
   }
   #iconic_topbar .menu_super{
     bottom: 65px;
   }
 
   #iconic_topbar .menu_list{
     width: 100%;
     /*bottom: 15px;*/
     text-align: center;
     font-size: min(1.7vw, 14px);
   }
   #iconic_topbar .menu_list .menu_main.m1{
     margin-left: 0;
   }
   #iconic_topbar .menu_list .menu_main{
     padding-left: 22px;
     margin-left: 18px;
   }
 
   #iconic_topbar .menu_super {
     margin-bottom: -7px;
   }
 }
 @media only screen and (max-width: 900px) {
   #iconic_footer .social div {
     text-align: center;
     width: 24%;
     vertical-align: top;
   }
   #iconic_footer .social img {
     margin-right: 0;
     display: block;
     margin: auto;
     margin-bottom: 5px;
   }
 }
 @media only screen and (max-width: 768px) {
   .break_mobile{
     border-top: 6px solid #ddd;
     margin: auto -15px;
   }
   .crumbs{
     padding: 0;
     padding-left: 15px;
   }
   .crumbs a, .crumbs span{
     display: none;
   }
   .crumbs a:last-of-type{
     display: inline-block;
     font-size: 0;
     width: 35px;
     height: 25px;
     background-image: url(./../images/ic_crumbs_left_b.png);
     background-repeat: no-repeat;
     background-size: contain;
   }
 
   body{
     padding-top: 50px;
   }
   .show_mobile{
     display: block;
   }
   .show_pc{
     display: none;
   }
 
   #iconic_topbar{
     height: 50px;
     position: fixed;
   }
   #iconic_topbar .menu_bg{
     background-color: #fff;
     width: 100%;
     position: absolute;
     z-index: auto;
     height: 50px;
     border-bottom: 1px solid #ddd;
   }
   #iconic_topbar .menu_logo{
     width: 100%;
     max-width: 140px;
     height: 65%;
     top: 17%;
     left: 40px;
     margin: auto;
   }
   #iconic_topbar .menu_super {
     top: 12px;
   }
   #iconic_topbar .menu_super {
     right: 10px;
   }
 
   #iconic_topbar .menu_list{
     width: 100%;
     max-width: 300px;
     top: 50px;
     left: -300px;
     text-align: left;
     font-size: min(5.5vw, 14px);
     height: 100%;
     position: fixed;
     background-color: #fff;
     padding-bottom: 70px;
     transition: 0.3s;
     z-index: 20;
     overflow: auto;
     border-top: 1px solid #ccc;
   }
   #iconic_topbar .menu_list.show{
     left: 0;
   }
   #iconic_topbar .menu_list .menu_main{
     padding: 10px 10px 10px 40px;
     margin-left: 0;
     width: 100%;
     border-bottom: 1px solid #d0d0d0;
     background-size: auto 40%;
     background-position: 15px;
     position: relative;
     font-weight: 500;
   }
   #iconic_topbar .menu_list .menu_main.selected{
     border-bottom: 0px;
     text-decoration: underline;
     color: #333;
   }
   #iconic_topbar .menu_list .menu_main.selected i{
     background-image: url(./../images/ic_minus.png);
   }
   #iconic_topbar .menu_list .menu_main i{
     width: 35px;
     height: 100%;
     position: absolute;
     right: 0;
     top: 0;
     background-image: url(./../images/ic_plus.png);
     background-size: 10px;
     background-repeat: no-repeat;
     background-position: center;
   }
   #iconic_topbar .menu_list .menu_sub a{
     padding: 5px 10px 10px 40px;
     margin-left: 0;
     width: 100%;
     background-size: auto 40%;
     background-position: 15px;
     font-weight: normal;
   }
   #iconic_topbar .menu_list .menu_sub{
     border-bottom: 1px solid #d0d0d0;
     border-top: 0px;
     position: relative;
     top: 0;
     padding: 0;
     padding-bottom: 5px;
     font-size: min(5vw, 14px);
   }
   #iconic_topbar .menu_super .menu_account {
     margin-bottom: -5px;
   }
   .menu_ic_close {
     display: block;
     position: absolute;
   }
   .menu_fader{
     background-color: rgba(0, 0, 0, 0.5);
   }
 
   #iconic_topbar .menu_search_box {
     width: 40%;
     height: 37px;
     top: 6px;
     margin-left: -18%;
   }
 
   #iconic_footer .tab_content {
     padding: 0;
   }
   #iconic_footer .enews {
     width: 100%;
   }
   #iconic_footer .social {
     width: 100%;
     padding: 15px;
     border-left: 0px;
     border-top: solid 1px #ddd;
     padding-bottom: 10px;
   }
   #iconic_footer .sitemap {
     padding: 20px 0;
   }
   #iconic_footer .link a{
     padding: 0 5px;
     font-size: min(3vw, 12px);
     width: 32%;
     vertical-align: top;
     display: inline-block;
   }
   #iconic_footer .tab_content .share b{
     display: none;
   }
   #iconic_footer .tab_content .share {
     width: 33%;
   }
   #iconic_footer .tab_content .share a {
     padding: 13px 10px 12px 0px;
     width: 40%;
     max-width: 50px;
   }
   #iconic_footer .column, #iconic_footer .column.md, #iconic_footer .column.lg{
     width: 100%;
     font-size: min(3vw,13px);
   }
   #iconic_footer .column_mobile{
     float: left;
     width: 50%;
   }
 }
 @media only screen and (max-width: 480px) {
   #popup_container.sign_in .bt.sn {
     width: 100%;
     margin-top: 10px;
   }
   .br_480{
     display: block;
   }
 }
 
 
 
 /*  Relate Thumb  */
 
 .buy_fix .buy {
   color: #000!important;
   background-color: #fff!important;
   background-image: url(./../images/ic_cart.png)!important;
 }
 
 .relate .tab_head {
     font-weight: normal;
     font-size: min(6vw,32px);
     margin: 20px;
     text-align: center;
     padding-top: 40px;
 }
 
 .relate .discount{
   padding-left: 5px;
   font-size: min(2.6vw, 12px);
   color: red;
   text-decoration: line-through;
 }
 .relate .ribbon{
   width: 45px;
   height: 45px;
   position: absolute;
   right: 20px;
   top: 0;
   font-size: min(3vw, 12px);
   font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
   color: #fff;
   padding: 0;
   padding-top: 13px;
   background-image: url(./../images/ribbon_hot.png);
   background-repeat: no-repeat;
   background-position: bottom;
 }
 .relate .ribbon.hot{
   background-image: url(./../images/ribbon_new.png);
 }
 .relate .ribbon.new{
   background-image: url(./../images/ribbon_hot.png);
 }
 .relate .ribbon.discount{
   text-decoration: none;
   padding-top: 7px;
   font-size: min(2vw, 10px);
   background-image: url(./../images/ribbon_discount.png);
 }
 .relate .ribbon.discount i{
   font-size: min(3vw, 12px);
   display: block;
   padding: 0;
   margin: 0;
   font-style: normal;
 }
 .relate.products, .relate.article{
   padding-bottom: 30px;
   margin-bottom: 10px;
 }
 .relate.products .thumb .thumb_content_holder{
   border: 1px solid #ddd;
   margin-bottom: 15px;
 }
 .relate.products .thumb .thumb_content{
   padding: 0 10px;
 }
 .relate.products .thumb div{
   display: block;
   font-size: min(2.8vw, 15px);
   text-align: center;
   min-height: 100px;
   margin-bottom: 50px;
 }
 .relate.products .thumb div img{
   padding-bottom: 10px;
   margin-top: 0px;
 }
    .relate.products .thumb div b {
        display: block;
        font-weight: normal;
        font-size: min(3.3vw, 18px);
        text-align: left;
        position: absolute;
        bottom: 70px;
        left: 30px;
    }
 .relate.products .thumb div .buy{
   width: 40px;
   cursor: pointer;
   height: 40px;
   position: absolute;
   right: 25px;
   bottom: 30px;
   border-radius: 99px;
   background-color: #6795c9;
   background-image: url(./../images/ic_cart_over.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 50%;
 
 }
 
 .relate.products .thumb .thumb_content_holder:hover{
   box-shadow: 5px 3px 15px #ccc;
   cursor: pointer;
 }
 
 .relate.products .thumb .thumb_content_holder_over, .relate.products .thumb .thumb_content_holder_over:hover{
   text-decoration: none;
   color: #444;
 }
 .relate.products .thumb .thumb_content_holder_over:hover ~ .buy{
   background-color: #000;
 }
 
 .relate.products .thumb div .view, .relate.products .thumb div .bt_view{
   cursor: pointer;
   width: 40px;
   height: 40px;
   position: absolute;
   right: 25px;
   bottom: 30px;
   border-radius: 99px;
   background-image: url(./../images/ic_cart_over.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 50%;
   background-color: #6795c9;
   transition-duration: 0.1s;
 }
 
 @media only screen and (max-width: 900px) {
   .relate.products .thumb div b {
     text-align: center;
     position: relative;
     bottom: auto;
     left: auto;
     padding-top: 10px;
   }
 }
 @media only screen and (max-width: 800px) {
   .relate.products .thumb div b {
     text-align: center;
     position: relative;
     bottom: auto;
     left: auto;
     padding-top: 10px;
   }
 }
 /*
 .relate.products .thumb .thumb_content_holder_over:hover ~ .bt_view{
   display: none;
 }
 
 .relate.products .thumb .thumb_content_holder:hover ~ .bt_view{
   display: none;
 }
 .relate.products .thumb .thumb_content_holder_over:hover ~ .bt_view{
   display: none;
 }
 
 .relate.products .thumb .thumb_content_holder_over:hover ~ .viewx .view{
   background-color: #000;
 }
 */
 span.view i {
   width: 10px;
   height: 10px;
   background-image: url(./../images/ic_right_b.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   display: inline-block;
   margin-left: 2px;
 }
 .relate.products .thumb div .view:hover, .relate.products .thumb div .bt_view:hover{
   width: 46px;
   height: 46px;
   right: 22px;
   bottom: 27px;
   background-color: #000;
 }
 span.view:hover i{
   background-image: url(./../images/ic_right_w.png);
 }
 .relate .buy_fix .buy {
   color: #000;
   background-color: #fff;
   background-image: url(./../images/ic_cart.png);
 }
 
 
 
 .thumb .bt_addcart {
   cursor: pointer;
   width: 40px;
   height: 40px;
   position: absolute;
   right: 25px;
   bottom: 30px;
   border-radius: 99px;
   background-image: url(./../images/ic_cart_over.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 50%;
   background-color: #6795c9;
   transition-duration: 0.1s;
   display: none;
 }
 .thumb .bt_addcart:hover {
   width: 46px ;
   height: 46px ;
   right: 22px ;
   bottom: 27px ;
 }
 .thumb_content_holder:hover .bt_addcart {
   display: block;
 }
 
 
 .relate.article {
   padding-top: 10px;
 }
 .relate.article .row {
   padding: 0 5px;
 }
 .relate.article .thumb {
   margin-bottom: 30px;
   padding: 10px;
 }
 .relate.article.content{
   padding: 0 5px 0 20px;
 }
 .relate.article .thumb_content_holder{
   background-color: #f9f9f9;
   width: 100%;
   margin: 0;
   padding: 0;
   cursor: pointer;
   display: inline-block;
 }
 .relate.article .swiper-wrapper{
   /*padding-bottom: 40px;*/
 }
 .relate.article .swiper-slide{
   padding-right: 15px;
   padding-bottom: 15px;
 }
 .relate.article .thumb_content_holder:hover{
   box-shadow: 5px 3px 12px #ccc;
 }
 .relate.article .thumb_content_holder img{
   max-width: 250px;
   display: inline-block;
   float: left;
   width: 39%;
 }
 .relate.article .thumb_content_holder .thumb_content{
   display: inline-block;
   text-align: left;
   float: right;
   font-size: min(1.6vw, 18px);
   width: 57%;
   padding-right: 20px;
 }
 .relate.article .thumb_content_holder b{
   color: #af976d;
   display: block;
   padding: 6% 0 5px;
   font-size: min(1.2vw, 16px);
 }
 .relate.article .thumb_content_holder span{
   color: #333;
   padding: 4% 6%;
   border: 1px solid #ddd;
   display: block;
   width: fit-content;
   margin-top: 7%;
   font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
   font-size: min(1.2vw, 12px);
   cursor: pointer;
 }
 .relate.article .thumb_content_holder span i{
   width: 10px;
   height: 10px;
   background-image: url(./../images/ic_right_d.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   display: inline-block;
   margin-left: 2px;
 }
 .relate.article .thumb_content_holder span:hover{
   color: #fff;
   background-color: #3f98cc;
 }
 .relate.article .thumb_content_holder:hover span{
   color: #fff;
   background-color: #3f98cc;
 }
 .relate.article .thumb_content_holder:hover span i{
   background-image: url(./../images/ic_right_w.png);
 }
 .relate.article .thumb {
   margin-bottom: 0;
 }
 
 
 @media only screen and (max-width: 768px) {
   .thumb .bt_addcart {
     display: block;
   }
   .relate.products .thumb div{
     min-height: 100px;
   }
 }
 @media only screen and (max-width: 640px) {
   /*.relate.products .thumb div .buy, .relate.products .thumb div .view {
     font-size: min(3.4vw, 14px);
     width: 100%;
     border-left: 0px;
   }
   .relate.products .thumb div .buy{
     color: #fff;
     padding-left: 40%;
     background-color: #3f98cc;
     background-image: url(./../images/ic_cart_over.png);
     background-size: 8%;
     background-position: 30% 45%;
   }*/
   .relate.products .thumb div {
     font-size: min(3.4vw, 15px);
   }
   .relate.products .thumb div b {
     font-size: min(4.4vw, 22px);
   }
 
   /* Fix Mouse Over on Mobile */
   .relate.products .thumb .thumb_content_holder:hover{
     box-shadow: none;
   }
   .relate .buy_fix .buy {
     color: #fff!important;
     background-color: #3f98cc!important;
     background-image: url(./../images/ic_cart_over.png)!important;
   }
   .relate.products .thumb div .buy:hover{
     color: #fff!important;
     background-image: url(./../images/ic_cart_over.png);
     background-color: #3f98cc!important;
   }
   /*.relate.products .thumb div .buy:hover, .product .thumb div .view:hover{
     color: inherit;
     background-color: inherit;
   }
   .relate.products .thumb div .buy:hover, .relate.products .thumb div .view:hover{
     color: #444;
     background-color: #fff;
   }*/
   .relate.products_detail .photo{
     width: 100%;
   }
   span.view:hover i{
     background-image: url(./../images/ic_right_b.png);
   }
   /*.relate.products .thumb .thumb_content_holder_over:hover ~ .buy{
     color: #333;
     background-color: inherit;
     background-image: url(./../images/ic_cart_over.png);
   }*/
   .relate.products .thumb .thumb_content_holder_over, .relate.products .thumb .thumb_content_holder_over:hover{
     text-decoration: none;
     color: #444;
   }
   body {
       padding-top: 105px;
   }
   #iconic_topbar .menu_search {
     width: 100%;
     padding: 15px;
     display: block;
     height: 55px;
     top: 50px;
     background-color: #fff;
     position: absolute;
     z-index: -1;
     border-bottom: 1px solid #dcdcdc;
   }
   #iconic_topbar .menu_search_box {
     width: 93%;
     height: 37px;
     top: 5px;
     right: auto;
     left: auto;
     margin-left: auto;
   }
   #iconic_topbar .menu_bg {
     /*height: 100px;*/
     border-bottom: 0px;
   }
   #popup_container.email input {
       width: 100%;
       margin-bottom: 10px;
   }
   #popup_container.email input.left {
       margin-right: 0px;
   }
   #iconic_topbar .popup #popup_container.email {
     padding: 20px;
   }
 }
 
 @media only screen and (max-width: 767px) {
   .relate.article.content {
     padding: 0;
     padding-left: 15px;
     /*padding-right: 15px;*/
   }
   .relate.article .thumb_content_holder {
     margin-bottom: 0;
   }
   .relate.article .thumb_content_holder img {
     max-width: 400px;
     width: 100%;
   }
   .relate.article .thumb_content_holder div b {
     font-size: min(2.5vw, 18px);
   }
   .relate.article .thumb_content_holder div {
     width: 100%;
     padding: 0 3% 0% 5%;
     font-size: min(3.5vw, 24px);
     padding-bottom: 30px;
   }
   .relate.article .thumb_content_holder div span {
     font-size: min(3.1vw, 14px);
     padding: 5% 10%;
   }
   .relate.article .thumb_content_holder .thumb_content {
     width: 100%;
     padding-right: 20px;
     font-size: min(4.6vw, 16px);
   }
 }
 
 @media only screen and (max-width: 375px) {
   .product .thumb div .buy {
     padding-left: 30%;
     background-size: 12%;
     background-position: 15% 45%;
   }
   #iconic_topbar .menu_logo {
     max-width: 110px;
     height: 60%;
     left: 47px;
     margin-top: 2px;
   }
   #iconic_topbar .menu_super .menu_account_super {
     background-size: 70%;
     width: 30px;
     height: 30px;
     margin-top: -2px;
   }
   #iconic_topbar .menu_super .menu_account_super.active {
     width: 28px;
     height: 28px;
     margin-top: -2px;
     margin-right: 4px;
   }
   #iconic_topbar .menu_super .menu_cart_super {
     background-size: 70%;
     width: 30px;
     height: 30px;
     margin-top: -2px;
   }
   #iconic_topbar .menu_super .menu_language {
     padding-left: 8px;
   }
 }
 
 
 #smartbanner { position:absolute; left:0; top:-82px; border-bottom:1px solid #e8e8e8; width:100%; height:78px; font-family:'Helvetica Neue',sans-serif; background:-webkit-linear-gradient(top, #f4f4f4 0%,#cdcdcd 100%); background-image: -ms-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%); background-image: -moz-linear-gradient(top, #F4F4F4 0%, #CDCDCD 100%); box-shadow:0 1px 2px rgba(0,0,0,0.5); z-index:9998; -webkit-font-smoothing:antialiased; overflow:hidden; -webkit-text-size-adjust:none; }
 #smartbanner, html.sb-animation {-webkit-transition: all .3s ease;}
 #smartbanner .sb-container { margin: 0 auto; }
 #smartbanner .sb-close { position:absolute; left:5px; top:5px; display:block; border:2px solid #fff; width:14px; height:14px; font-family:'ArialRoundedMTBold',Arial; font-size:15px; line-height:15px; text-align:center; color:#fff; background:#070707; text-decoration:none; text-shadow:none; border-radius:14px; box-shadow:0 2px 3px rgba(0,0,0,0.4); -webkit-font-smoothing:subpixel-antialiased; }
 #smartbanner .sb-close:active { font-size:13px; color:#aaa; }
 #smartbanner .sb-icon { position:absolute; left:30px; top:10px; display:block; width:57px; height:57px; background:rgba(0,0,0,0.6); background-size:cover; border-radius:10px; box-shadow:0 1px 3px rgba(0,0,0,0.3); }
 #smartbanner.no-icon .sb-icon { display:none; }
 #smartbanner .sb-info { position:absolute; left:98px; top:18px; width:44%; font-size:11px; line-height:1.2em; font-weight:bold; color:#6a6a6a; text-shadow:0 1px 0 rgba(255,255,255,0.8); }
 #smartbanner #smartbanner.no-icon .sb-info { left:34px; }
 #smartbanner .sb-info strong { display:block; font-size:13px; color:#4d4d4d; line-height: 18px; }
 #smartbanner .sb-info > span { display:block; }
 #smartbanner .sb-info em { font-style:normal; text-transform:uppercase; }
 #smartbanner .sb-button { position:absolute; right:20px; top:24px; padding: 0 10px; min-width: 10%; height:24px; font-size:14px; line-height:24px; text-align:center; font-weight:bold; color:#6a6a6a; background:-webkit-linear-gradient(top, #efefef 0%,#dcdcdc 100%); text-transform:uppercase; text-decoration:none; text-shadow:0 1px 0 rgba(255,255,255,0.8); border-radius:3px; box-shadow:0 1px 0 rgba(255,255,255,0.6),0 1px 0 rgba(255,255,255,0.7) inset; }
 #smartbanner .sb-button:active, #smartbanner .sb-button:hover { background:-webkit-linear-gradient(top, #dcdcdc 0%,#efefef 100%); }
 
 #smartbanner .sb-icon.gloss:after { content:''; position:absolute; left:0; top:-1px; border-top:1px solid rgba(255,255,255,0.8); width:100%; height:50%; background:-webkit-linear-gradient(top, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.2) 100%); border-radius:10px 10px 12px 12px; }
 
 #smartbanner.android { border-color:#212228; background: #3d3d3d url('data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7'); border-top: 5px solid #88B131; box-shadow: none; position: fixed; z-index: 9999;  top: 0; }
 #smartbanner.android .sb-close { border: 0;width: 20px;height: 20px;line-height: 20px;color: #b1b1b3;background: #1c1e21;text-shadow: 0 1px 1px #000;box-shadow: 0 1px 2px rgba(0,0,0,0.8) inset, 0 1px 1px rgba(255,255,255,0.3);margin-top: 20px;}
 #smartbanner.android .sb-close:active { color:#eee; }
 #smartbanner.android .sb-info { color:#ccc; text-shadow:0 1px 2px #000; }
 #smartbanner.android .sb-info strong { color:#fff; }
 #smartbanner.android .sb-button { min-width: 15%;padding: 1px;color: #ffffff;background: none;border-radius: 0;box-shadow: none;min-height: 30px;}
 #smartbanner.android .sb-button span { text-align: center; display: block; padding: 2px 13px;border-radius: 5px; background-color: #3a97cd; background-image: -webkit-gradient(linear,0 0,0 100%,from(#42B6C9),to(#3a97cd)); background-image: -moz-linear-gradient(top,#42B6C9,#39A9BB); text-transform:none; text-shadow:none; box-shadow:none; }
 #smartbanner.android .sb-button:active, #smartbanner.android .sb-button:hover { background: none; }
 #smartbanner.android .sb-button:active span, #smartbanner.android .sb-button:hover span { background:#2AC7E1; }
 
 #smartbanner.windows .sb-icon { border-radius: 0px; }
 
 .smartbanner_fixtopbar{top: 79px !important;}
 
 
 
 
 /* Hide Content */
 /*.description .status, .description .quantity , .description .bt_addcart, .description .bt_buynow{
   display: none!important;
 }
 
 .description .delivery{
   display: none!important;
 }
 
 .description hr{
   display: none!important;
 }*/
 