html, body, div, p, li, table, h1, h2, h3, h4 ,h5,button {
 font-family:'open_sansregular','promptregular', arial, helvetica, sans-serif;
}
.open_sanslight{font-family: 'open_sanslight','promptlight';}
.open_sansregular{font-family: 'open_sansregular','promptregular';}
.open_sansbold{font-family: 'open_sansbold','promptmedium';}
.open_sansextrabold{font-family: 'open_sansextrabold','promptbold';}


@font-face {
    font-family: 'open_sanslight';
    src: url('opensans-light.woff2') format('woff2'),
         url('opensans-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('opensans-regular.woff2') format('woff2'),
         url('opensans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('opensans-bold.woff2') format('woff2'),
         url('opensans-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansextrabold';
    src: url('opensans-extrabold.woff2') format('woff2'),
         url('opensans-extrabold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'promptlight';
    src: url('prompt-light.woff2') format('woff2'),
         url('prompt-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'promptregular';
    src: url('prompt-regular.woff2') format('woff2'),
         url('prompt-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'promptmedium';
    src: url('prompt-medium.woff2') format('woff2'),
         url('prompt-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'promptbold';
    src: url('prompt-bold.woff2') format('woff2'),
         url('prompt-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
