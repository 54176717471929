/*!
* Iconic Pharmacy
* https://www.iconicpharmacy.co.th
* @license Copyright 2020, Iconic Pharmacy. All rights reserved.
* @author: atthaphon@dnaagency.co.th
*/

@media (min-width: 768px) {
    #product {
        width: calc(100% - 250px) !important
    }
}

@media (max-width: 768px) {
    #product {
        width: 100% !important
    }

 
}

.hide-scoll ::-webkit-scrollbar {
    display: none;
}

.hide-scoll {
    -ms-overflow-style: none;
    overflow-y:hidden;
}
.img_size {
    width: 100%;
    height: 184px;
    object-fit: cover;
}

@media (width: 768px) {
    .img_size {
        width: 100%;
        height: 350px;
        object-fit: cover;
    }
    .thumb_content_holder .buy_button .bn {
        background-size: 25%;
    }
   
}

@media (min-width: 769px) {
    .img_size {
        width: 100%;
        height: 203px;
        object-fit: cover;
    }
    .relate.products .thumb div .buy_button{
        font-size: min(2.8vw, 14px);
    }
 
}
#highlight {
    max-width: 1680px;
    position: relative;
    margin: 0 auto;
    padding-bottom: 40px;
    overflow: hidden;
}

.buy_fix .buy {
    color: #000 !important;
    background-color: #fff !important;
    background-image: url(./../images/ic_cart.png) !important;
}

.content {
    width: 100%;
    max-width: 1280px;
    position: relative;
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

    .content .side {
        width: 250px;
        display: inline-block;
        float: left;
        min-width: 250px;
        padding: 0 20px 0 15px;
        transition-duration: 0.3s;
    }

        .content .side .menu {
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }

        .content .side h2 {
            font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
            padding-bottom: 7px;
            margin-bottom: 10px;
            font-size: min(4vw,15px);
            text-align: left;
            background-image: url(./../images/ic_dropdown_g_over.png);
            background-size: 10px;
            background-position: right 5px;
            background-repeat: no-repeat;
            cursor: pointer;
        }

            .content .side h2.collapse {
                background-image: url(./../images/ic_dropdown_g.png);
            }

        .content .side .price h2 {
            background-image: none;
            cursor: auto;
        }
        /*
  .content .side .product h2{
    border-bottom: 1px solid #ddd;
  }
  */
        .content .side .list {
            display: block;
            padding: 1px 0px 0px 22px;
            font-size: min(4vw,13px);
            position: relative;
            margin-bottom: 10px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

.side .list i {
    background-image: url(./../images/ic_dropdown_g.png);
    background-size: 100%;
    background-position: right;
    background-repeat: no-repeat;
    float: right;
    width: 10px;
    height: 10px;
    opacity: 0.6;
}

.side .list input:checked ~ i {
    background-image: url(./../images/ic_dropdown_g_over.png);
}
/* The container */
.side .list:hover {
    text-decoration: underline;
}
/* Hide the browser's default checkbox */
.side .list input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.side .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #999;
}
/* On mouse-over, add a grey background color */
.side .list:hover input ~ .checkmark {
    background-color: #eee;
}
/* When the checkbox is checked, add a blue background */
.side .list input:checked ~ .checkmark {
    background-color: #3e9acd;
    border: 1px solid #3e9acd;
}
/* Create the checkmark/indicator (hidden when not checked) */
.side .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.side .list input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.side .list .checkmark:after {
    left: 3px;
    top: -1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.side .list_sub label {
    display: block;
    position: relative;
    margin-left: 20px;
}

.side .price input {
    border: 1px solid #ddd;
    padding: 5px;
    width: 40%;
    display: inline-block;
    color: #999;
    vertical-align: middle;
}

.content .side .price.menu {
    border-bottom: none;
}

.content .side .price #price_max {
    color: #3e9acb;
}

.content .main {
    width: 100%;
    /* padding-left: 300px; */
    float: left;
    text-align: left;
    font-size: min(4vw,13px);
    padding-right: 15px;
}

.content.product {
    width: 100%;
    padding: 20px;
}

.content h2 {
    text-align: center;
    font-size: min(7vw,36px);
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
    width: 100%;
    display: block;
    margin-bottom: 30px;
}

.main .filter_btn {
    float: right;
    padding: 10px 2% 10px 10%;
    background-color: #3e98cc;
    color: #fff;
    margin-right: 15px;
    background-image: url(./../images/ic_filter.png);
    background-size: 17%;
    background-repeat: no-repeat;
    background-position: 10%;
    cursor: pointer;
}

    .main .filter_btn:hover {
        background-color: #2b82b4;
    }

.main .filter_by {
    padding-left: 15px;
    vertical-align: middle;
    height: auto;
    margin-bottom: 15px;
}

    .main .filter_by b {
        font-weight: normal;
    }

    .main .filter_by span {
        color: #fff;
        background-color: #3e98cc;
        border-radius: 99px;
        padding: 7px 30px 7px 15px;
        background-image: url(./../images/ic_x.png);
        background-size: 25%;
        background-repeat: no-repeat;
        background-position: 95%;
        margin-left: 7px;
        cursor: pointer;
        display: inline-block;
    }

        .main .filter_by span:hover {
            background-color: #358dc0;
        }

.filter_close {
    width: 40px;
    height: 40px;
    background-image: url(./../images/ic_x_b.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: auto;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 3px;
}

    .filter_close:hover {
        background-image: url(./../images/ic_x_c.png);
    }

.side .filter_by {
    vertical-align: middle;
    height: auto;
    margin-bottom: 15px;
}

    .side .filter_by b {
        display: block;
        /*margin-bottom: 5px;*/
    }

    .side .filter_by span {
        color: #fff;
        background-color: #3e98cc;
        border-radius: 99px;
        padding: 7px 30px 7px 15px;
        background-image: url(./../images/ic_x.png);
        background-size: 25%;
        background-repeat: no-repeat;
        background-position: 95%;
        margin-left: 7px;
        cursor: pointer;
        display: inline-block;
    }

        .side .filter_by span:hover {
            background-color: #358dc0;
        }

.filter_fade {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    display: none;
}

.filter_button {
    position: relative;
    bottom: 0;
    width: 220px;
    margin-left: 0px;
    margin-top: 15px;
}


    .filter_button .bt {
        padding: 12px;
        width: 50%;
        display: inline-block;
        text-align: center;
        float: left;
        cursor: pointer;
        font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
        color: #fff;
        background-color: #4e9abf;
        border-right: 1px solid #fff;
    }

        .filter_button .bt:hover {
            background-color: #0b7faa;
        }

.buy_button .bt {
    padding: 12px;
    width: 70%;
    display: inline-block;
    text-align: center;
    float: left;
    cursor: pointer;
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
    color: #fff;
    background-color: #4e9abf;
    border-right: 1px solid #fff;
    margin-top: -41px;
    background-image: url(./../images/ic_bag.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 10%;
    background-position: 15% 45%;
    height: 40px;
}

.buy_button .bn {
    padding: 0px;
    width: 30%;
    display: inline-block;
    text-align: center;
    float: left;
    cursor: pointer;
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
    color: #4e9abf;
    background-color: #fff;
    border: 2px solid #4e9abf;
    margin-top: -41px;
    background-image: url(./../images/ic_cart.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
    height: 40px;
    margin-left: 70%;
}

    .buy_button .bt:hover {
        background-color: #0b7faa;
    }

    .buy_button .bn:hover {
        background-color: #EBF5FB;
    }

    .main .sort_by {
        padding-left: 15px;
        float: left;
    }

    .main .sort_by select {
        color: #777;
        border: 1px solid #ccc;
        padding: 7px 30px 7px 10px;
        margin-left: 10px;
        vertical-align: middle;
    }

.main .item {
    padding-right: 15px;
    float: right;
}

    .main .item b {
        font-size: min(5vw,16px);
        font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
        font-weight: normal;
    }

.discount {
    padding-left: 5px;
    font-size: min(2.6vw, 12px);
    color: red;
    text-decoration: line-through;
}

.ribbon {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 20px;
    top: 0;
    font-size: min(3vw, 12px);
    font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
    color: #fff;
    padding: 0;
    padding-top: 13px;
    background-image: url(./../images/ribbon_hot.png);
    background-repeat: no-repeat;
    background-position: bottom;
}

    .ribbon.hot {
        background-image: url(./../images/ribbon_hot.png);
    }

    .ribbon.new {
        background-image: url(./../images/ribbon_new.png);
    }

    .ribbon.discount {
        text-decoration: none;
        padding-top: 7px;
        font-size: min(2vw, 10px);
        background-image: url(./../images/ribbon_discount.png);
    }

        .ribbon.discount i {
            font-size: min(3vw, 12px);
            display: block;
            padding: 0;
            margin: 0;
            font-style: normal;
        }

        .ribbon.discount.detail {
            width: 60px;
            height: 60px;
            font-size: min(3vw, 16px);
            text-align: center;
            z-index: 8;
        }

            .ribbon.discount.detail i {
                font-size: min(4vw, 16px);
            }


.product .thumb .thumb_content_holder {
    border: 1px solid #ddd;
    margin-bottom: 15px;
}

.product .thumb .thumb_content {
    padding: 0 10px;
}

.product .thumb div {
    display: block;
    font-size: min(2.8vw, 16px);
    text-align: center;
    min-height: 100px;
}

    .product .thumb div img {
        padding-bottom: 10px;
    }

    .product .thumb div b {
        display: block;
        font-weight: normal;
        font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
        font-size: min(3.4vw, 20px);
        padding: 5px 10px 15px 10px;
    }

    .product .thumb div .buy {
        display: inline-block;
        width: 50%;
        font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
        font-size: min(1.6vw, 11px);
        padding: 15px 0;
        border-top: 1px solid #ddd;
        cursor: pointer;
        padding-left: 15%;
        float: left;
        background-image: url(./../images/ic_cart.png);
        background-size: 13%;
        background-position: 12% 45%;
        background-repeat: no-repeat;
        text-align: left;
    }

        .product .thumb div .buy:hover {
            background-image: url(./../images/ic_cart_over.png);
        }

.product .thumb .thumb_content_holder:hover {
    box-shadow: 5px 3px 15px #ccc;
    cursor: pointer;
}
/*
  .product .thumb .thumb_content_holder:hover .buy{
    color: #fff;
    background-color: #3f98cc;
    background-image: url(./../images/ic_cart_over.png);
  }
  */
.product .thumb .thumb_content_holder_over, .product .thumb .thumb_content_holder_over:hover {
    text-decoration: none;
    color: #444;
}

    .product .thumb .thumb_content_holder_over:hover ~ .buy {
        color: #fff;
        background-color: #3f98cc;
        background-image: url(./../images/ic_cart_over.png);
    }

.product .thumb div .view {
    display: inline-block;
    width: 50%;
    font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
    font-size: min(1.6vw, 11px);
    padding: 15px 0;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    cursor: pointer;
}

    .product .thumb div .buy:hover, .product .thumb div .view:hover {
        color: #fff;
        background-color: #3f98cc;
    }

.buy_fix .buy {
    color: #000;
    background-color: #fff;
    background-image: url(./../images/ic_cart.png);
}

.main .page {
    text-align: right;
    padding-right: 15px;
    padding-bottom: 40px;
    margin-top: -20px;
}

    .main .page a {
        display: inline-block;
        padding: 7px 10px;
        border: 1px solid #ddd;
    }

        .main .page a:hover {
            color: #fff;
            background-color: #3f98cc;
        }

        .main .page a.selected {
            color: #fff;
            border: 1px solid #3f98cc;
            background-color: #3f98cc;
        }

        .main .page a.more {
            border: none;
        }

/* Product Detail */
.content.product_detail {
    padding-left: 15px;
    padding-right: 15px;
    display: block;
}

.product_detail .tab_head {
    display: block;
    clear: both;
    padding-top: 40px;
    text-align: center;
}

.product_detail .description h1 {
    display: block;
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: min(4vw,22px);
    font-weight: normal;
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
}

.product_detail .description h2 {
    display: block;
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: min(6vw,32px);
    font-weight: normal;
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
    margin: 5px 0;
}

.product_detail .text {
    font-size: min(4vw,18px);
    margin: 20px 0;
}

.description .price {
    font-weight: normal;
    font-size: min(6vw,32px);
    letter-spacing: -0.08vw;
    margin-bottom: 20px;
}

    .description .price .discount {
        font-weight: normal;
        font-size: min(3vw,18px);
    }

.description .status {
    margin-bottom: 20px;
}

.description .quantity {
    margin-bottom: 10px;
}

    .description .quantity span {
        width: 40px;
        height: 40px;
        display: inline-block;
        background-color: #fff;
        cursor: pointer;
        font-size: 25px;
        text-align: center;
        color: #b19669;
        vertical-align: top;
        line-height: 35px;
    }

        .description .quantity span:hover {
            background-color: #4198ce;
            color: #fff;
        }

        .description .quantity span.left {
            font-size: 30px;
        }

    .description .quantity input {
        width: 60px;
        height: 40px;
        display: inline-block;
        background-color: #fff;
        border: none;
        vertical-align: top;
        text-align: center;
        font-size: min(4vw, 18px);
        margin: 0 2px;
    }

.content .tab_head {
    /*font-weight: normal;
    font-size: min(6vw,32px);
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
    margin: 20px;*/
    font-weight: normal;
    font-size: min(6vw,32px);
    font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
    margin: 20px;
    margin-top: 0;
    padding-top: 40px;
}

.content .tab_content .tab {
    padding: 20px 30px;
    padding-right: 80px;
    padding-bottom: 10px;
    border: 1px solid #e9e9e9;
    border-bottom: none;
    background-color: #f9f9f9;
    cursor: pointer;
    transition-duration: 0.2s;
}

    .content .tab_content .tab:last-child {
        border: 1px solid #ddd;
    }

    .content .tab_content .tab i {
        width: 35px;
        height: 35px;
        margin-right: -60px;
        float: right;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        background-image: url(./../images/products_detail/ic_plus.png);
    }

    .content .tab_content .tab span {
        display: none;
    }

    .content .tab_content .tab.selected i {
        background-image: url(./../images/products_detail/ic_minus.png);
    }

    .content .tab_content .tab.selected {
        background-color: #fff;
        padding-bottom: 30px;
        cursor: auto;
    }

        .content .tab_content .tab.selected span {
            display: block;
        }

    .content .tab_content .tab b {
        font-weight: normal;
        font-size: min(4.8vw,20px);
        font-family: 'promptmedium', 'NotoSansSC-Bold', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Bold', 'open_sansbold';
        display: block;
        margin-bottom: 10px;
    }

.product_detail .photo {
    width: 45%;
    display: inline-block;
    float: left;
    padding-right: 20px;
}

.swiper-slide-cover {
    background-image: url(./../images/products_detail/inner_shadow.png);
    background-size: 100%;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    pointer-events: none;
}

.gallery-top {
    height: 80%;
    width: 100%;
}

.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
    padding-right: 1px; /* fix border ขวา ของ thumb สุดท้ายหาย */
}

    .gallery-thumbs .swiper-slide {
        width: 25%;
        height: 100%;
        /*opacity: 0.8;*/
        cursor: pointer;
        border: 1px solid #fff;
    }

    .gallery-thumbs .swiper-slide-thumb-active {
        /*opacity: 0.8;*/
        border: 1px solid #3e98cc;
    }

    .gallery-thumbs .swiper-slide-cover {
        opacity: 0;
    }

    .gallery-thumbs .swiper-slide-thumb-active .swiper-slide-cover {
        opacity: 0.2;
        /*background-image: url(./../images/products_detail/inner_shadow_over.png);*/
    }

.product_detail .description {
    width: 55%;
    display: inline-block;
    float: right;
    background-color: #f9f9f9;
    padding: 30px;
    padding-bottom: 60px;
}

.product_detail .status {
}

    .product_detail .status i {
        width: 9px;
        height: 9px;
        display: inline-block;
        border-radius: 9px;
        background-color: #999;
    }

        .product_detail .status i.green {
            background-color: #81bd03;
        }

        .product_detail .status i.red {
            background-color: #d22a27;
        }

        .product_detail .status i.orange {
            background-color: #e17b0d;
        }

.description .bt_addcart, .description .bt_buynow {
    font-size: min(3vw, 14px);
    padding: 15px 30px;
    border: 1px solid #ddd;
    display: inline-block;
    min-width: 180px;
    margin-right: 10px;
    text-align: center;
    padding-left: 50px;
    font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
    background-repeat: no-repeat;
    background-position: 15%;
    background-size: 20px;
    cursor: pointer;
    margin-bottom: 10px;
}

.description .bt_addcart {
    background-image: url(./../images/products_detail/ic_cart_over.png);
}

.description .bt_buynow {
    background-color: #3e98cc;
    border: 1px solid #3e98cc;
    color: #fff;
    background-image: url(./../images/products_detail/ic_basket.png);
}

.description .bt_addcart:hover {
    background-color: #3e98cc;
    border: 1px solid #3e98cc;
    color: #fff;
    background-image: url(./../images/products_detail/ic_cart.png);
}

.description .bt_buynow:hover {
    background-color: #2477a6;
    border: 1px solid #2477a6;
    background-image: url(./../images/products_detail/ic_basket.png);
}

.product_detail .share {
    margin: 10px 0;
}

    .product_detail .share span {
        width: 35px;
        height: 40px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 65%;
        cursor: pointer;
        vertical-align: middle;
        opacity: 0.7;
    }

        .product_detail .share span:hover {
            opacity: 1;
        }

        .product_detail .share span.fb {
            background-image: url(./../images/products_detail/ic_facebook.png);
        }

        .product_detail .share span.tw {
            background-image: url(./../images/products_detail/ic_twitter.png);
        }

        .product_detail .share span.ig {
            background-image: url(./../images/products_detail/ic_instagram.png);
        }

.product_detail .delivery .option {
    margin: 10px 0;
}

.product_detail .delivery b {
    font-size: min(3.6vw,18px);
    font-weight: normal;
    display: block;
    margin-bottom: 2px;
}

.product_detail .delivery .tooltip {
    width: 25px;
    height: 25px;
    float: right;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    background-image: url(./../images/products_detail/ic_warning.png);
    cursor: pointer;
    z-index: 10;
}

.product_detail .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    top: 120%;
    right: -20px;
    margin-left: -60px;
    opacity: 0.6;
    text-align: left;
}

    .product_detail .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 28px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
    }

.product_detail .tooltip:hover .tooltiptext {
    visibility: visible;
}

.product_detail .delivery span {
    display: inline-block;
    font-size: min(3vw,12px);
    color: #666;
    width: 60%;
}

    .product_detail .delivery span b {
        font-size: min(3.4vw,15px);
        color: #3e98cc;
    }

.product_detail .delivery u {
    float: right;
    padding-top: 15px;
    font-style: normal;
    text-decoration: none;
}

.product_detail .delivery i {
    width: 27px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 2px;
    background-size: contain;
    margin-right: 20px;
    vertical-align: top;
}

.product_detail .delivery .shipping i {
    background-image: url(./../images/products_detail/ic_box.png);
}

.product_detail .delivery .cash i {
    background-image: url(./../images/products_detail/ic_cash.png);
}

.product_detail .delivery .recive i {
    background-image: url(./../images/products_detail/ic_store.png);
}

.product_detail .delivery .return i {
    background-image: url(./../images/products_detail/ic_7.png);
}

/* Fix Relate Product margin */
.relate.products #product {
    padding: 5px;
}

#article .row {
    padding: 0 5px;
}

#article .thumb {
    margin-bottom: 30px;
    padding: 10px;
}

#article.content {
    padding: 0 5px 0 20px;
}

#article .thumb_content_holder {
    background-color: #f9f9f9;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: inline-block;
}

#article .swiper-wrapper {
    /*padding-bottom: 40px;*/
}

#article .swiper-slide {
    padding-right: 15px;
    padding-bottom: 15px;
}

#article .thumb_content_holder:hover {
    box-shadow: 5px 3px 12px #ccc;
}

#article .thumb_content_holder img {
    max-width: 250px;
    display: inline-block;
    float: left;
    width: 39%;
}

#article .thumb_content_holder .thumb_content {
    display: inline-block;
    text-align: left;
    float: right;
    font-size: min(1.6vw, 18px);
    width: 57%;
    padding-right: 20px;
}

#article .thumb_content_holder b {
    color: #af976d;
    display: block;
    padding: 6% 0 5px;
    font-size: min(1.5vw, 18px);
}

#article .thumb_content_holder span {
    color: #333;
    padding: 4% 6%;
    border: 1px solid #ddd;
    display: block;
    width: fit-content;
    margin-top: 7%;
    font-family: 'promptbold', 'NotoSansSC-Black', 'amiribold', 'padaukbold', 'hanumanbold', 'NotoSansJP-Black', 'open_sansextrabold';
    font-size: min(1.2vw, 12px);
    cursor: pointer;
}

    #article .thumb_content_holder span:hover {
        color: #fff;
        background-color: #3f98cc;
    }

#article .thumb_content_holder:hover span {
    color: #fff;
    background-color: #3f98cc;
}

.relate #article .thumb {
    margin-bottom: 0;
}
/* Fix */
.relate.products, .relate.article {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.relate.article {
    padding-bottom: 40px;
}

.relate.products {
    padding-top: 10px;
    /*padding: 20px;
    padding-bottom: 40px;*/
}

    .relate.products.content {
        padding: 15px;
    }

@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 900px) {
    .product .thumb div {
        min-height: 80px;
    }

        .product .thumb div .buy, .product .thumb div .view {
            font-size: min(3vw, 11px);
        }
}

@media only screen and (max-width: 768px) {
    .filter_button {
        position: fixed;
        bottom: 0;
        width: 300px;
        margin-left: -25px;
        margin-top: 0px;
    }

    .page_load.show_mobile {
        display: block;
    }

    .page.show_pc {
        display: none;
    }

    .product .thumb div {
        font-size: min(2.8vw, 16px);
    }

    .relate.article {
        padding-top: 10px;
        padding-bottom: 40px;
    }

    .content .tab_content {
        padding-bottom: 40px;
    }

    .relate.products {
        padding: 0px;
    }

    .content .side {
        width: 300px;
        display: inline-block;
        float: left;
        min-width: 250px;
        position: absolute;
        top: 0;
        right: -300px;
        z-index: 32;
        background-color: #fff;
        position: fixed;
        padding: 50px 15px 60px 25px;
        overflow: auto;
        height: 100%;
    }

        .content .side.show {
            right: 0px;
        }

    .side .filter_by span {
        margin: 5px 5px 0 0;
        font-size: min(3vw,12px);
        padding: 5px 20px 5px 10px;
    }

    .content .main {
        width: 100%;
        float: left;
        text-align: left;
        padding-right: 0;
    }

    .main .sort_by {
        width: 60%;
        max-width: 300px;
    }

        .main .sort_by .text {
            display: none;
        }

        .main .sort_by select {
            padding: 7px 30px 7px 10px;
            margin-left: 0;
            width: 100%;
        }

    .product .thumb div .buy, .product .thumb div .view {
        font-size: min(3.4vw, 14px);
        width: 100%;
        border-left: 0px;
    }

    .product .thumb div .buy {
        color: #fff;
        padding-left: 40%;
        background-color: #3f98cc;
        background-image: url(./../images/ic_cart_over.png);
        background-size: 8%;
        background-position: 30% 45%;
    }

    .product .thumb div {
        font-size: min(4vw, 16px);
    }

        .product .thumb div b {
            font-size: min(4.4vw, 22px);
        }

    .content h2 {
        text-align: left;
        font-size: min(7vw,36px);
        margin-left: 0;
        margin-right: -7px;
        margin-bottom: 20px;
    }

    .view_all {
        margin-top: -40px;
    }

    /* Fix Mouse Over on Mobile */
    .product .thumb .thumb_content_holder:hover {
        box-shadow: none;
    }

    .buy_fix .buy {
        color: #fff !important;
        background-color: #3f98cc !important;
        background-image: url(./../images/ic_cart_over.png) !important;
    }

    .product .thumb div .buy:hover, .product .thumb div .view:hover {
        color: inherit;
        background-color: inherit;
    }

    .product_detail .photo {
        width: 100%;
    }

    /*.relate.products, .relate.article {
        padding-top: 0px;
    }*/
    /* Products Detail */
    .product_detail .description {
        width: 100%;
        padding: 30px 15px;
    }

    .product_detail .photo {
        padding-right: 0;
    }

    #article.content {
        padding: 0;
        padding-left: 15px;
        /*padding-right: 15px;*/
    }

    #article .thumb_content_holder {
        margin-bottom: 0;
    }

        #article .thumb_content_holder img {
            max-width: 400px;
            width: 100%;
        }

        #article .thumb_content_holder div b {
            font-size: min(2.5vw, 18px);
        }

        #article .thumb_content_holder div {
            width: 100%;
            padding: 0 3% 0% 5%;
            font-size: min(3.5vw, 24px);
            padding-bottom: 30px;
        }

            #article .thumb_content_holder div span {
                font-size: min(3.1vw, 14px);
                padding: 5% 10%;
            }

        #article .thumb_content_holder .thumb_content {
            width: 100%;
            padding-right: 20px;
            font-size: min(4.6vw, 16px);
        }

    .content .tab_content .tab i {
        width: 30px;
        height: 30px;
        margin-right: -65px;
        margin-top: -5px;
    }
}

@media only screen and (max-width: 375px) {
    .product .thumb div .buy {
        padding-left: 30%;
        background-size: 12%;
        background-position: 15% 45%;
    }
}


@media (max-width: 1080px) {
    .thumb.col-lg-3.col-md-4.col-xs-6 {
        width: 33.333%;
    }
}

@media (max-width: 820px) {
    .thumb.col-lg-3.col-md-4.col-xs-6 {
        width: 50%;
    }
}
