#iconic_topbar {
  .nav-item {
    display: inline;
    text-align: left;
  }
  &.navbar {
    display: block;
    padding-top: 0px;
  }

  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: unset;
    flex-grow: unset;
  }
  .navbar-nav {
    display: block;
  }
  .container-fluid {
    padding: unset;
    display: block;
  }
  .collapse.show .menu_list {
    left: 0;
  }
  .nav-link {
    padding: 0;
  }

  @media only screen and (max-width: 768px) {
    .menu_list .menu_main.m1 {
      height: 36px;
    }
    .nav-item .dropdown-menu {
      background-color: transparent;
      border: none;

      .dropdown-item {
        padding: 0.5rem 0 0 0;
      }
    }

    .menu_main_container {
      width: 100%;
    }

    .menu_list.show {
      z-index: 999999;
    }
  }

  .menu_main_container {
    display: inline-block;
  }
}

.filter_btn {
  float: right;
  padding: 10px 2% 10px 10%;
  background-color: #3e98cc;
  color: #fff;
  margin-right: 15px;
  background-image: url(./../../../images/ic_filter.png);
  background-size: 17%;
  background-repeat: no-repeat;
  background-position: 10%;
  cursor: pointer;
}
.side .filter_by span.selected-filter,
.main .filter_by span.selected-filter {
  background-size: 1.5rem;
  background-position-x: calc(100% - 6px);
}
@media only screen and (max-width: 768px) {
  .side .filter_by span.selected-filter,
  .main .filter_by span.selected-filter {
    background-size: 1rem;
    background-position-x: calc(100% - 1px);
  }
  .side .filter_by span.selected-filter {
    padding: 5px 17px 5px 10px;
  }

  .content .main .filter_by.show_mobile b {
    font-weight: bold;
  }

  .content .main .filter_by.show_mobile {
    padding-left: 0px;
  }
}

.product .sub-category {
  margin-left: 1rem;
}

.main .filter_by.show_pc span.selected-filter {
  margin: 5px 5px 0 7px;
}

.content .side .product.list.product_c div {
  margin-left: 10px;
}

h2 {
  font-weight: bold;
}

body .content .main {
  font-size: unset;
}

.filter_by.show_pc b.filter_label {
  padding-left: 15px;
  line-height: 40px;
}

.content .main .sidetab_brand_see_all {
  text-decoration: underline;
  cursor: pointer;
}

.content.product_detail .description .bt_addcart,
.content.product_detail .description .bt_buynow {
  min-width: 177px;
}
.content.product_detail .description .bt_buynow {
  margin-right: 0px;
}
